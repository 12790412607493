<template>
  <div class="contact-page padding-left-layout padding-top-layout padding-right-layout">
    <page-title :title="textTitle" />
    <div v-if="linkToPage === 'index'" class="form-data">
      <div class="page-header">
        <span>
          {{ $t('contact.label_inquiries_regarding') }}
        </span>
        <span>
          {{ $t('contact.description_contact_with_you') }}
        </span>
      </div>
      <div class="contact-wrapper">
        <div class="contact-form-type">
          <div class="header">
            <span> {{ $t('contact.label_name') }}</span>
            <div class="required-text">
              <span>{{ $t('contact.label_required') }}</span>
            </div>
          </div>
          <input-field
            :label="$t('contact.placeholder_enter_name')"
            class="mt-2"
            v-model="contactForm.name"
            :inputValue="contactForm.name"
          />
        </div>

        <div class="contact-form-type">
          <div class="header">
            <span>{{ $t('contact.label_company_name') }}</span>
            <div class="required-text">
              <span>{{ $t('contact.label_required') }}</span>
            </div>
          </div>
          <input-field
            :label="$t('contact.placeholder_enter_title')"
            class="mt-2"
            v-model="contactForm.companyName"
            :inputValue="contactForm.companyName"
          />
        </div>

        <div class="contact-form-type">
          <v-row>
            <v-col cols="6">
              <div class="mr-6">
                <div class="header">
                  <span>{{ $t('contact.label_telephone_number') }}</span>
                  <div class="required-text">
                    <span>{{ $t('contact.label_required') }}</span>
                  </div>
                </div>
                <input-field
                  :label="$t('contact.placeholder_phone_number')"
                  class="mt-2"
                  v-model="contactForm.phone"
                  :inputValue="contactForm.phone"
                  :errorMessages="errorMessagesPhone"
                  @focus="errorMessagesPhone = []"
                  @blur="handleCheckPhoneNumber"
                />
              </div>
            </v-col>

            <v-col cols="6">
              <div class="ml-6">
                <div class="header">
                  <span>{{ $t('contact.label_email_address') }}</span>
                  <div class="required-text">
                    <span>{{ $t('contact.label_required') }}</span>
                  </div>
                </div>
                <input-field
                  :label="$t('contact.placeholder_enter_email_address')"
                  class="mt-2"
                  v-model="contactForm.email"
                  :inputValue="contactForm.email"
                  @blur="handleCheckEmail"
                  @focus="errorMessagesEmail = []"
                  :errorMessages="errorMessagesEmail"
                />
              </div>
            </v-col>
          </v-row>
        </div>

        <div class="contact-form-input">
          <div class="header">
            <span>{{ $t('contact.label_content_inquiry') }}</span>
            <div class="required-text">
              <span>{{ $t('contact.label_required') }}</span>
            </div>
          </div>
          <div class="form-input">
            <textarea
              v-model="contactForm.contactData"
              name="form-input"
              cols="30"
              rows="10"
              :class="{ 'error-textarea': isFormError && contactForm.contactData === '' }"
            >
            </textarea>
          </div>
          <div v-if="isFormError && contactForm.contactData === ''" class="error-message">
            {{ defaultErrorMessage }}
          </div>
        </div>
        <div class="contact-form-checkbox">
          <div class="checkbox-container">
            <div @click="contactForm.isCheck = !contactForm.isCheck" class="checkbox">
              <img v-if="contactForm.isCheck" src="@/assets/icons/checkbox.svg" alt="" class="checkmark" />
            </div>
          </div>
          <a :href="convertQueryToUrlString('/others/privacy')" class="link-to-page" target="_blank">
            <p class="move-new-page mb-0">
              {{ $t('contact.hyperlink_privacy_policy') }}<span class="underline"></span>
            </p>
            <img src="@/assets/icons/linkToNewPage.svg" alt="" />
          </a>
          <div class="agree-text">{{ $t('contact.label_agree_to') }}</div>
        </div>
        <div class="contact-submit-btn" @click="linkToPage = 'confirm'" :class="validateFormData() && 'disabled'">
          <span>{{ $t('contact.button_check_your_entries') }}</span>
        </div>
      </div>
    </div>
    <confirm-page v-if="linkToPage === 'confirm'" :link-to-page.sync="linkToPage" :contactForm="contactForm" />
    <success-page v-if="linkToPage === 'success'" :link-to-page.sync="linkToPage" :contact-form.sync="contactForm" />
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import { ROUTES } from '@/router/constants';
import PageTitle from '@/components/pageTitle/pageTitle.vue';
import DefaultPullDownMenu from '@/components/pulldown/DefaultPullDownMenu';
import InputField from '@/components/products/input/InputField.vue';
import ConfirmPage from '@/views/supplier/contact/confirm.vue';
import SuccessPage from '@/views/supplier/contact/success.vue';
import validate from '@/utils/validate';
export default {
  components: { PageTitle, DefaultPullDownMenu, ConfirmPage, SuccessPage, InputField },
  data() {
    return {
      breadcrum: [
        {
          text: this.$t('contact.hyperlink_home'),
          disabled: false,
          href: ROUTES.SUPPLIER,
        },
        {
          text: this.$t('contact.label_contact_us'),
          disabled: true,
          href: ROUTES.PRODUCTS + ROUTES.CONTACT,
        },
      ],
      contactType: [],
      isFormError: false,
      contactForm: {
        name: '',
        companyName: '',
        phone: '',
        email: '',
        contactData: '',
        isCheck: false,
      },
      linkToPage: 'index',
      defaultErrorMessage: this.$t('contact.error_message_error'),
      hoverPolicy: false,
      errorMessagesEmail: '',
      errorMessagesPhone: [],
    };
  },
  mounted() {
    this.updateBreadCrumb(this.breadcrum);
  },
  computed: {
    contactFormStore() {
      return this.$store.state.userData.contactForm;
    },
    textTitle() {
      return this.$t('contact.title_contact_us');
    },
    typePlaceholder() {
      return this.$t('contact.pulldown_select_please_select_type');
    },
  },
  watch: {
    linkToPage: {
      handler() {
        if (this.linkToPage === 'index') {
          this.breadcrum = [
            {
              text: this.$t('contact.hyperlink_home'),
              disabled: false,
              href: ROUTES.PRODUCTS,
            },
            {
              text: this.$t('contact.label_contact_us'),
              disabled: true,
              href: ROUTES.PRODUCTS + ROUTES.CONTACT,
            },
          ];
        } else if (this.linkToPage === 'confirm') {
          this.breadcrum = [
            {
              text: this.$t('contact.hyperlink_home'),
              disabled: false,
              href: ROUTES.PRODUCTS,
            },
            {
              text: this.$t('contact.label_contact_us'),
              disabled: true,
              href: ROUTES.PRODUCTS + ROUTES.CONTACT,
            },
            {
              text: this.$t('contact.label_confirm_input'),
              disabled: true,
              href: ROUTES.PRODUCTS + ROUTES.CONTACT,
            },
          ];
        } else {
          this.breadcrum = [
            {
              text: this.$t('contact.hyperlink_home'),
              disabled: false,
              href: ROUTES.PRODUCTS,
            },
            {
              text: this.$t('contact.label_contact_us'),
              disabled: true,
              href: ROUTES.PRODUCTS + ROUTES.CONTACT,
            },
            {
              text: this.$t('contact.label_completion'),
              disabled: true,
              href: ROUTES.PRODUCTS + ROUTES.CONTACT,
            },
          ];
        }
        this.updateBreadCrumb(this.breadcrum);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      },
    },
  },
  methods: {
    ...mapActions('commonApp', ['updateBreadCrumb']),
    ...mapActions('userData', ['actionupdateContactForm']),

    validateFormData() {
      let validateForm = Object.keys(this.contactForm).filter(
        (key) => (key != 'isCheck' && !this.contactForm[key]),
      );

      const validateEmail = !validate.regexMail.test(this.contactForm.email);
      const validatePhone = !validate.regexPhoneNumber.test(this.contactForm.phone);

      return validateForm.length !== 0 || !this.contactForm.isCheck || validateEmail || validatePhone;
    },
    convertQueryToUrlString(url) {
      let params = '';
      let querySize = 0;
      for (const [key, value] of Object.entries(this.$route?.query)) {
        querySize += 1;
        if (!params.includes(key)) {
          params += (querySize === Object.keys(this.$route?.query)?.length) ? `${key}=${value}` : `${key}=${value}&`;
        }
      }
      return `${url}?${params}`;
    },
    handleCheckEmail(email) {
      if (email) {
        if (!validate.regexMail.test(email)) {
          this.errorMessagesEmail.push(this.$t('user_management.message_wrong_format_mail'));
        }
      }
    },

    handleCheckPhoneNumber(phone) {
      if (phone) {
        if (!validate.regexPhoneNumber.test(phone)) {
          this.errorMessagesPhone.push(this.$t('supplier.validate_phone_number'));
        }
      } 
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/views/contact/styles/index.scss';
</style>
<style lang="scss">
.contact-pulldown {
  .selection-box-bridge {
    height: 40px;
    padding-bottom: unset !important;
    .input-box {
      line-height: 24px;
    }
  }
  &.error-pulldown {
    border: 2px solid $redMid;
  }
  &.active {
    border: 2px solid $blueMid !important;
  }
  .selection-pulldown {
    margin-top: 4px;
  }
}
</style>
