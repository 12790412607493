import webStorage from '@/utils/webStorage';

const contactSlackHook = (data, type) => {
  let payload = {
    ...data,
    'type': type,
  }
  const response = fetch('https://hooks.slack.com/services/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', {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify({"text": JSON.stringify(payload)}), // body data type must match "Content-Type" header
  });
  return response;
}

export {contactSlackHook}